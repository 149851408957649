
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainLayoutBg {
    background: url("/assets/images/mainLayoutBg.svg") no-repeat bottom right;
    @media screen and (max-width: 1870px) {
        background-size: 650px;
    }
    @media screen and (max-width: 1185px) {
        background-size: 550px;
    }
    @media screen and (max-width: 767px) {
        background-size: 420px;
    }
}
